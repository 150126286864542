<script setup lang="ts">
const local_site = useLocalSite();

// 各市区町村の施工例件数を取得する
const { data: sikuCount } = await useFetch("/api/sekou/site/area", {
  default: () => [],
});

// 全市区町村の施工例件数の合計
const total = computed(() => {
  return sikuCount.value.reduce((acc, cur) => acc + cur.count, 0);
});
</script>

<template>
  <div class="flex flex-col tablet:flex-row w-full gap-4 mb-8 tablet:mb-16">
    <p
      class="flex tablet:flex-col justify-center items-center px-4 text-orange-900 whitespace-nowrap"
    >
      <span>{{ local_site.shop_name }}</span>
      <span>施工例実績</span>
      <span class="font-bold">
        <span class="text-lg"> {{ total }} </span>
        件</span
      >
    </p>
    <ul class="flex-grow grid grid-cols-2 tablet:grid-cols-3 pc:grid-cols-5">
      <SekouAreaLink :shikuAreas="sikuCount" />
    </ul>
  </div>
</template>
