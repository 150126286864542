<script setup lang="ts">
const local_site = useLocalSite();
</script>

<template>
  <ButtonTelLink
    class="block p-4 hover:underline rounded mb-8 tablet:mb-16 tablet:w-fit tablet:mx-auto"
    :tel="local_site.tel"
  >
    <p class="font-thin tracking-wide text-4xl w-fit mx-auto">
      <span class="text-sm">TEL</span>{{ local_site.tel }}
    </p>
    <p class="w-fit mx-auto">
      <span class="text-sm tracking-widest pc:text-xl tablet:text-lg"
        >10:00~18:00</span
      >(定休日:水・木曜日)
    </p>
  </ButtonTelLink>
</template>
