<script setup lang="ts">
definePageMeta({ layout: "no-bread-crumbs" });

// メタ施工例カウント
const attr = await useSekouAttr();
const metaCount = {
  open: 0,
  carport: 0,
  hei: 0,
};
if (attr.value && attr.value.length) {
  attr.value?.forEach((a) => {
    if (a.attr_num == 1) {
      metaCount.open = a.count;
    } else if (a.attr_num == 107) {
      metaCount.hei = a.count;
    } else if (a.category == 6 && a.cate_name.match(/カーポート/)) {
      metaCount.carport += a.count;
    }
  });
}

useHead(homePageHeadTemplate(metaCount));

// @ts-ignore
defineOgImageComponent("Base");
</script>

<template>
  <div>
    <!-- notice -->
    <NewsNotice class="my-5" />
    <!-- banner -->
    <SwiperTopBanner />

    <LayoutWrapperDiv :noPadding="true">
      <SlideTopRecommend class="mb-8 tablet:mb-16" />
      <div class="px-2 mb-8 tablet:mb-16">
        <SekouMenuKeywordSearch />
      </div>
      <SekouAreaPrefecture />
    </LayoutWrapperDiv>

    <TopContent class="mb-8 tablet:mb-16" />

    <TopCompany class="bg-green-50 py-4 mb-8 tablet:mb-16" />

    <LayoutWrapperDiv class="flex gap-12 justify-center mb-8 tablet:mb-16">
      <NuxtLink
        to="https://www.instagram.com/ex.komikomi/"
        aria-label="ファミリー庭園公式Instagram"
      >
        <Icon size="52" name="skill-icons:instagram" />
      </NuxtLink>
      <NuxtLink
        to="https://twitter.com/family_teien"
        aria-label="ファミリー庭園公式Twitter"
      >
        <Icon size="52" name="ri:twitter-x-line" />
      </NuxtLink>
      <NuxtLink
        to="https://www.pinterest.jp/ex_komikomi/"
        aria-label="ファミリー庭園公式Pinterest"
      >
        <Icon size="52" name="logos:pinterest" />
      </NuxtLink>
    </LayoutWrapperDiv>

    <!-- instagram -->
    <TopInstagram class="py-4 mb-8 tablet:mb-16" />

    <!-- contact -->
    <TopContactBanner />

    <!-- calendar -->
    <section>
      <LayoutWrapperDiv class="items-center pc:flex mb-16">
        <div class="pc:w-1/2 mx-auto">
          <h2 class="text-lg font-bol w-fit mx-auto mb-2">営業カレンダー</h2>
          <CalendarMain />
        </div>
      </LayoutWrapperDiv>
    </section>
  </div>
</template>
