<template>
  <LayoutWrapperDiv>
    <TextMincho
      tag="h2"
      class="text-4xl text-slate-500 border-b-4 border-double mx-2 px-2 pc:mx-auto tablet:text-5xl mb-8"
    >
      <slot />
    </TextMincho>
  </LayoutWrapperDiv>
</template>
